export const openInNewTab = (link, { ...args } = {}) => {
	if (typeof link === 'object') {
		if ('class' in link && link.class && link?.class !== 'disabled') {
			return window.open(link.url, '_blank').focus();
		}
		if (
			'validator' in link &&
			link.validator !== '' &&
			args.status === 'validated' &&
			link.status.includes('validated')
		) {
			return window.open(link.validator, '_blank').focus();
		}
	}

	if (typeof link === 'string' && link !== '' && link !== '#') {
		return window.open(link, '_blank').focus();
	}
	return;
};
