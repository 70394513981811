export function getListFromI18JSON(json, pageKey, targetKey) {
	let count = 0;
	const arr = [];
	for (const item of Object.entries(json)) {
		if (item[0].includes(targetKey)) {
			arr.push(`${pageKey}.${targetKey}${++count}`);
		}
	}
	return arr;
}
