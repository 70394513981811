import { createI18n } from 'vue-i18n';
// import { getStorage } from '../utils/storage';

export const i18n = createI18n({
	// locale: getStorage('lang') || 'en',
	locale: 'en',
	legacy: false,
	globalInjection: true,
	fallbackLocale: ['en'],
	messages: {
		en: require('@/plugins/languages/en.json'),
		ru: require('@/plugins/languages/ru.json'),
		ch: require('@/plugins/languages/ch.json')
	}
});

export default i18n;
