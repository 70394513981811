export default {
	state: () => ({
		docs: [
			{
				title: 'extensionsPage.docs.doc1.title',
				description: 'extensionsPage.docs.doc1.description',
				bannerTitle: 'extensionsPage.docs.doc1.bannerTitle',
				bannerImg: `${require('@/assets/images/extensions/docs/dev-center.png')}`,
				link: {
					url: '',
					name: 'buttons.availableSoon',
					class: 'btn green docs-btn'
				}
			},
			{
				title: 'extensionsPage.docs.doc2.title',
				description: 'extensionsPage.docs.doc2.description',
				bannerTitle: 'extensionsPage.docs.doc2.bannerTitle',
				bannerImg: `${require('@/assets/images/extensions/docs/ui-kit.png')}`,
				link: {
					url: '',
					name: 'buttons.availableSoon',
					class: 'btn black docs-btn'
				}
			}
		],
		repositories: [
			{
				title: 'extensionsPage.repos.osmosis.title',
				description: 'extensionsPage.repos.osmosis.description',
				icon: 'osmosis',
				status: ['repository', 'show-case'],
				urls: {
					repository: null,
					'show-case': 'https://app.citadel.one/extensions/Osmosis'
				},
				img: `${require('@/assets/images/extensions/show-case/osmosis.png')}`
			},
			{
				title: 'extensionsPage.repos.1inch.title',
				description: 'extensionsPage.repos.1inch.description',
				icon: '1inch',
				status: ['repository'],
				urls: {
					repository: null,
					'show-case': null
				}
			},
			{
				title: 'extensionsPage.repos.secret.title',
				description: 'extensionsPage.repos.secret.description',
				icon: 'secret',
				status: ['repository'],
				urls: {
					repository: null,
					'show-case': null
				}
			},
			{
				title: 'extensionsPage.repos.cosmos-gov.title',
				description: 'extensionsPage.repos.cosmos-gov.description',
				icon: 'cosmos-gov',
				status: ['repository', 'show-case'],
				urls: {
					repository: null,
					'show-case':
						'https://app.citadel.one/extensions/Cosmos-governance'
				},
				img: `${require('@/assets/images/extensions/show-case/cosmos-gov.png')}`
			},
			{
				title: 'extensionsPage.repos.pancakeswap.title',
				description: 'extensionsPage.repos.pancakeswap.description',
				icon: 'pancakeswap',
				status: ['show-case'],
				urls: {
					repository: null,
					'show-case':
						'https://app.citadel.one/extensions/Pancakeswap'
				},
				img: `${require('@/assets/images/extensions/show-case/pancakeswap.png')}`
			},
			{
				title: 'extensionsPage.repos.autorestake.title',
				description: 'extensionsPage.repos.autorestake.description',
				icon: 'autorestake',
				status: ['show-case'],
				urls: {
					repository: null,
					'show-case':
						'https://app.citadel.one/extensions/Autorestake'
				},
				img: `${require('@/assets/images/extensions/show-case/autorestake.png')}`
			}
		],
		connectLinks: [
			{
				url: 'mailto: develop@citadel.one',
				name: 'buttons.devMail',
				class: 'purple'
			},
			{
				url: '',
				name: 'buttons.devChat',
				class: 'green'
			}
		]
	}),

	getters: {
		getDocs: (state) => state.docs,
		getRepos: (state) =>
			state.repositories.filter((app) =>
				app.status.includes('repository')
			),
		getConnect: (state) => state.connectLinks,
		getShowCases: (state) =>
			state.repositories.filter((app) => app.status.includes('show-case'))
	},

	namespaced: true
};
