export default {
	state: () => ({
		articles: [
			{
				order: 1,
				parentClass: '',
				titleClass: '',
				title: 'Citadel.one joins StaFiHub Genesis Validator set',
				description: '',
				url: 'https://medium.com/sifchain-finance/citadel-launches-sifchain-app-c3ac1f49363a',
				date: '26/07/2022',
				img: ''
			},
			{
				order: 2,
				class: 'purple',
				titleClass: '',
				parentClass: 'reverse',
				title: 'Sifchain app is launched in Сitadel.one',
				description: '',
				url: 'https://smartliquidity.info/2022/07/26/stafihub-genesis-validator-citadel-one/  ',
				date: '14/05/2022',
				img: `${require('@/assets/images/about/articles/02.png')}`
			},
			{
				order: 3,
				parentClass: 'right',
				titleClass: 'row',
				orderClass: 'big',
				title: 'BSC News',
				class: 'big',
				description:
					'Citadel.one is an all-in-one platform addressing changes and challenges in the DeFi space that offers its users unified UX across multiple networks.',
				url: 'https://www.bsc.news/post/citadel-one-the-next-generation-non-custodial-super-app ',
				date: '27/06/2021',
				img: `${require('@/assets/images/about/articles/03.png')}`
			},
			{
				order: 4,
				parentClass: 'top',
				titleClass: '',
				class: 'purple',
				title: 'COSMOS SPACES',
				description: '',
				url: '',
				date: '01/08/2022',
				img: `${require('@/assets/images/about/articles/04.png')}`
			},
			{
				order: 5,
				parentClass: '',
				titleClass: '',
				title: 'PRO Indicators` video on Citadel.One XCT Stakedrop',
				description: '',
				url: 'https://www.youtube.com/watch?v=_0fCHyGz0lc ',
				date: '15/05/2022',
				img: ''
			},
			{
				order: 6,
				parentClass: '',
				titleClass: '',
				class: 'purple',
				title: 'Staking Rewards featuring Citadel.one',
				description: '',
				url: 'https://www.stakingrewards.com/savings/citadel/ ',
				date: '14/05/2022',
				img: ''
			},
			{
				order: 7,
				parentClass: 'right',
				title: 'pStake Blog',
				class: 'big',
				titleClass: 'row',
				description:
					'Citadel.one has released a pSTAKE extension for their staking & DeFi platform.',
				url: 'https://blog.pstake.finance/2022/04/15/citadel-one-has-integrated-pstake/',
				date: '15/04/2022',
				img: ''
			}
		]
	}),

	getters: {
		getArticles: (state) => state.articles
	},

	namespaced: true
};
