<template>
	<header class="page-header">
		<div class="site-nav">
			<nav :class="`${current} site-nav-container container`">
				<router-link to="/" class="citadel-one">
					<CitadelLogo v-if="!isSafari" />
					<img
						v-if="isSafari"
						src="@/assets/icons/others/citadel-logo.png"
						alt="citadel-logo"
					/>
				</router-link>

				<div class="header-menu">
					<a
						:href="guide"
						class="guides"
						:title="$t('buttons.guides')"
						target="_blank"
					>
						{{ $t('buttons.guides') }}
					</a>
					<ButtonLink :link="platform" />

					<div v-if="mobileLinks" className="header-mobile btn-group">
						<ButtonLink
							v-for="mobileLink in mobileLinks"
							:key="mobileLink"
							:link="mobileLink"
						/>
					</div>
				</div>
			</nav>
		</div>
	</header>
</template>

<script>
import CitadelLogo from '@/components/CitadelOne';
import ButtonLink from '@/components/Button/Link';
import store from '@/store';

export default {
	name: 'Header',
	components: {
		CitadelLogo,
		ButtonLink
	},
	props: {
		current: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			link: store.getters['links/links'],
			guide: store.getters['links/getGuidesLink'],
			mobileLinks: store.getters['links/GET_PLATFORM_MOBILE_LINKS'],
			platform: store.getters['links/getPlatformLinks'],
			isSafari: false
		};
	},
	mounted() {
		window.addEventListener('scroll', this.onScrollHeader);
		this.onScrollHeader();
		var ua = navigator.userAgent.toLowerCase();
		if (ua.indexOf('safari') != -1) {
			if (ua.indexOf('chrome') > -1) {
				this.isSafari = false;
			} else {
				this.isSafari = true;
			}
		}
	},
	methods: {
		onScrollHeader() {
			if (window.scrollY > 40) {
				document.querySelector('.site-nav').classList.add('active');
			} else {
				document.querySelector('.site-nav').classList.remove('active');
			}
			if (window.scrollY > 100) {
				const mobileBlock = document.querySelector(
					'.mobile-content.content'
				);
				if (mobileBlock) {
					mobileBlock.classList.add('animate');
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.page-header {
	height: 114px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	@include ipad {
		height: 50px;
	}
}
.site-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	padding: 64px 0 0;
	-webkit-animation: slideDown 0.4s forwards;
	animation: slideDown 0.4s forwards;
	transition: all 0.2s linear;
	&.active {
		padding: 25px 0;
		background-color: $color-black;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
			rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
	}
	@include ipad {
		padding: 25px 0;
	}
}
.site-nav-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: 0.3s linear;
	&.main.container {
		max-width: $container-main-max-width;
		width: 95%;
	}
}
.citadel-one {
	display: block;
	width: 100%;
	max-width: 225px;
	height: 32px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	img {
		width: 100%;
	}
	@include ipad {
		max-width: 165px;
	}
	@include mobile-l {
		max-width: 120px;
	}
}
.header-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.header-mobile.btn-group {
		display: none;
	}
	@include ipad {
		a.btn {
			display: none;
		}
	}
}
</style>
