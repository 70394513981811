export default {
	state: () => ({
		members: [
			{
				name: 'members.antonPavlutsky.name',
				image: require('@/assets/images/about/members/pavlutsky.png'),
				position: 'members.antonPavlutsky.position',
				description: 'members.antonPavlutsky.description',
				socials: {
					linkedin:
						'https://www.linkedin.com/in/anton-pavlutsky-4753b3204/'
				}
			},
			{
				name: 'members.alexFalko.name',
				image: require('@/assets/images/about/members/falco.png'),
				position: 'members.alexFalko.position',
				description: 'members.alexFalko.description',
				socials: {
					twitter: 'https://twitter.com/alfa_c1',
					linkedin: 'https://www.linkedin.com/in/alex-falko-a3b79289/'
				}
			},
			{
				name: 'members.gregoryShabalov.name',
				image: require('@/assets/images/about/members/shabalov.png'),
				position: 'members.gregoryShabalov.position',
				description: 'members.gregoryShabalov.description',
				socials: {
					twitter: 'https://twitter.com/grshabl',
					linkedin: 'https://www.linkedin.com/mwlite/in/shabalov'
				}
			},
			{
				name: 'members.rinaShtedle.name',
				image: require('@/assets/images/about/members/shtedle.png'),
				position: 'members.rinaShtedle.position',
				description: 'members.rinaShtedle.description',
				socials: {
					twitter: 'https://twitter.com/neshtedle',
					linkedin: 'https://www.linkedin.com/in/neshtedle'
				}
			},
			{
				name: 'members.dmitryShehovtsov.name',
				image: require('@/assets/images/about/members/shehovtsov.png'),
				position: 'members.dmitryShehovtsov.position',
				description: 'members.dmitryShehovtsov.description',
				socials: {
					linkedin:
						'https://www.linkedin.com/in/dmitrii-shekhovtsov-bba673189/'
				}
			},
			{
				name: 'members.pavelKhivintsev.name',
				image: require('@/assets/images/about/members/khivintsev.png'),
				position: 'members.pavelKhivintsev.position',
				description: 'members.pavelKhivintsev.description',
				socials: {
					linkedin:
						'https://www.linkedin.com/in/pavel-khivintsev-89667072/'
				}
			},
			{
				name: 'members.petrLapin.name',
				image: require('@/assets/images/about/members/lapin.png'),
				position: 'members.petrLapin.position',
				description: 'members.petrLapin.description',
				socials: {
					github: 'https://github.com/lapinlapin'
				}
			},
			{
				name: 'members.margoOmelchenko.name',
				image: require('@/assets/images/about/members/omelchenko.png'),
				position: 'members.margoOmelchenko.position',
				description: 'members.margoOmelchenko.description',
				socials: {
					linkedin:
						'https://www.linkedin.com/in/%D0%BE%D0%BC%D0%B5%D0%BB%D1%8C%D1%87%D0%B5%D0%BD%D0%BA%D0%BE-%D0%BC%D0%B0%D1%80%D0%B3%D0%B0%D1%80%D0%B8%D1%82%D0%B0-12514672/',
					behance: 'https://www.behance.net/margo_omelchenko',
					dribbble: 'https://dribbble.com/margo_omelchenko'
				}
			},
			// {
			// 	name: 'members.vladGorlov.name',
			// 	image: require('@/assets/images/about/members/gorlov.png'),
			// 	position: 'members.vladGorlov.position',
			// 	description: 'members.vladGorlov.description',
			// 	socials: {
			// 		linkedin: 'https://www.linkedin.com/in/vladgorlov/',
			// 		twitter: 'https://twitter.com/_iamvlad'
			// 	}
			// },
			{
				name: 'members.alexKhrohmal.name',
				image: require('@/assets/images/about/members/krokhmal.png'),
				position: 'members.alexKhrohmal.position',
				description: 'Multi-talented Community Lead who is responsible for community development, monitoring the community environment, implementing community activities, and additionally, managing the team of support managers.',
				socials: {
					linkedin: 'https://www.linkedin.com/in/oleksandr-krokhmal-219b4a163/',
					twitter: 'https://twitter.com/Crypto_Papam'
				}
			},
			{
				name: 'members.arinaKor.name',
				image: require('@/assets/images/about/members/kor.png'),
				position: 'members.arinaKor.position',
				description: 'Experienced content creator and editor. Responsible for content creation, editing, and implementing the social media strategy. In addition, Arina maintains media partnerships.',
				socials: {
					linkedin: 'www.linkedin.com/in/arina-kor-bb4735214'
				}
			}
		],
		advisors: [
			{
				name: 'team.advisors.aggarwal.name',
				description: 'team.advisors.aggarwal.description',
				image: require('@/assets/images/about/advisors/sunny-aggarwal.png'),
				social: {
					icon: 'twitter',
					link: 'https://twitter.com/sunnya97'
				}
			},
			{
				name: 'team.advisors.roux.name',
				description: 'team.advisors.roux.description',
				image: require('@/assets/images/about/advisors/philippe-roux.png'),
				social: {
					icon: 'twitter',
					link: 'https://twitter.com/Phil_RX'
				}
			},
			{
				name: 'team.advisors.calicchia.name',
				description: 'team.advisors.calicchia.description',
				image: require('@/assets/images/about/advisors/marco-calicchia.png'),
				social: {
					icon: 'linkedin',
					link: 'https://gr.linkedin.com/in/marco-calicchia-8a6a51231/it?trk=people-guest_people_search-card'
				}
			},
			{
				name: 'team.advisors.kisagun.name',
				description: 'team.advisors.kisagun.description',
				image: require('@/assets/images/about/advisors/can-kisagun.png'),
				social: {
					icon: 'linkedin',
					link: 'https://www.linkedin.com/in/cankisagun/'
				}
			}
		],
		teammates: [
			{
				name: 'Alexander Prokofiev',
				position: 'designer-mobile'
			},
			{
				name: 'Alisher Nurlybaev',
				position: 'backend'
			},
			{
				name: 'Arina Korobeinikova',
				position: 'editor-content'
			},
			{
				name: 'Azamat Akmatov',
				position: 'mobile'
			},
			{
				name: 'Bohdan Borysov',
				position: 'manager-project'
			},
			{
				name: 'Diana Maduan',
				position: 'frontend'
			},
			{
				name: 'Dmitry Kogut',
				position: 'backend'
			},
			{
				name: 'Dmitrii Sidorov',
				position: 'qa-mobile'
			},
			{
				name: 'Eduard Poghosyan',
				position: 'manager-project'
			},
			{
				name: 'Egor Reutov',
				position: 'qa-auto'
			},
			{
				name: 'Evgenii Vorontsov',
				position: 'mobile'
			},
			{
				name: 'Grigory Sargsyan',
				position: 'frontend'
			},
			{
				name: 'Helen Li',
				position: 'translate-community-china'
			},
			{
				name: 'Igor Teplinsky',
				position: 'web3'
			},
			{
				name: 'Ilia Malyshev',
				position: 'editor-lead'
			},
			{
				name: 'Kamilla Akhsanova',
				position: 'marketing'
			},
			{
				name: 'Maira Barat',
				position: 'frontend'
			},
			{
				name: 'Marina Mironova',
				position: 'designer-media'
			},
			{
				name: 'Mikhail Chutskov',
				position: 'backend'
			},
			{
				name: 'Nikita Pashkov',
				position: 'devOps'
			},
			{
				name: 'Oleksandr Krokhmal',
				position: 'translate-community-russia'
			},
			{
				name: 'Pavel Sudakov',
				position: 'qa-lead'
			},
			{
				name: 'Rahim Aimaganbetov',
				position: 'backend'
			},
			{
				name: 'Sergey Romanov',
				position: 'frontend',
				icon: 'frontend'
			},
			{
				name: 'Sofia Peremotina',
				position: 'qa'
			},
			{
				name: 'Stacy Kells',
				position: 'community'
			},
			{
				name: 'Tanny Boiarinova',
				position: 'manager-operation'
			},
			{
				name: 'Vladimir Chizganov',
				position: 'backend'
			},
			{
				name: 'Vlad Lusta',
				position: 'frontend'
			},
			{
				name: 'Yedil Akhmetkarimov',
				position: 'fullStack'
			},
			{
				name: 'Yegor Sibirskiy',
				position: 'community-senior'
			},
			{
				name: 'Yerassyl Murat',
				position: 'manager-project'
			},
			{
				name: 'Yeskendir Sarinov',
				position: 'backend'
			},
			{
				name: 'Vasiliy Lobarev',
				position: 'backend'
			},
			{
				name: 'Vitaliy Shestakov',
				position: 'mobile'
			},
			{
				name: 'Aniel Essien',
				position: 'community'
			},
			{
				name: 'M.C. Ikechi',
				position: 'community'
			},
			{
				name: 'Agustín do Rego',
				position: 'communication'
			},
			{
				name: 'Romain Cajina',
				position: 'translate-community-french'
			},
			{
				name: 'Sam Asfi',
				position: 'support'
			},
			{
				name: 'Shariar Saeik Swapnil',
				position: 'support'
			},
			{
				name: 'Shariful Islam',
				position: 'support-lead'
			}
		]
			.map((item) => {
				const image = item.name.toLowerCase().split(' ').join('-');

				item.icon = item.position.split('-')[0];
				item.position = `positions.${item.position}`;

				try {
					item.image = require(`@/assets/images/about/teammates/${image}.png`);
				} catch (ex) {
					item.image = require(`@/assets/images/about/teammates/default.png`);
				}

				return item;
			})
			.sort(function (prev, next) {
				var textA = prev.name.toLowerCase();
				var textB = next.name.toLowerCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			})
	}),

	getters: {
		getMembers: (state) => state.members,
		getTeammates: (state) => state.teammates,
		getAdvisors: (state) => state.advisors
	},

	namespaced: true
};
