const stringNotEmpty = (value) =>
	typeof value === 'string' && value.length != 0;

export const checkField = (data, field) => {
	const stringNotEmpty =
		typeof data[field] === 'string' &&
		data[field].length != 0 &&
		data[field].includes('-');
	const stringEmpty =
		typeof data[field] === 'string' && data[field]?.length === 0;
	const isNumber = typeof data[field] == 'undefined' && isNaN(data[field]);

	if (stringNotEmpty || stringEmpty || isNumber) {
		return {
			value: data[field],
			format: false
		};
	}

	if (data[field] !== undefined || data[field] !== 0) {
		return {
			value: data[field],
			format: true
		};
	}

	return {
		value: data[field],
		format: false
	};
};

export const checkFormField = (field, value) => {
	if (field === 'email' && stringNotEmpty(value)) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
	}
	return stringNotEmpty(value) || false;
};

export const isNumber = (event) => {
	event = event ? event : window.event;
	const charCode = event.which ? event.which : event.keyCode;

	const checkChars =
		charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46;
	const repeatedZero = event.target.value.startsWith('0');

	if (checkChars) {
		return event.preventDefault();
	}
	if (repeatedZero) {
		return (event.target.value = event.target.value.slice(1, 6));
	}

	return true;
};
