export default {
	MAIN_ROUTE: '/',
	PLATFORM_ROUTE: '/platform',
	STAKING_ROUTE: '/staking',
	EXTENSIONS_ROUTE: '/extensions',
	XCT_ROUTE: '/xct',
	ABOUT_US_ROUTE: '/about',
	DAO_ROUTE: '/dao',
	DOCS_ROUTE: '/docs',
	TERMS: '/terms',
	PRIVACY: '/privacy'
};
