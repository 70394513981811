import { setStorage } from '@/utils/storage.js';

const types = {
	SET_LOCALE: 'SET_LOCALE'
};

export default {
	state: () => ({
		locale: 'en',
		locales: [
			{ label: 'RU', key: 'ru' },
			{ label: 'EN', key: 'en' },
			{ label: 'CH', key: 'ch' }
		]
	}),

	getters: {
		locale: (state) => state.locale,
		locales: (state) => state.locales
	},

	actions: {
		init({ commit, state }) {
			commit(types.SET_LOCALE, state.locale);
		},
		setLocale({ commit }, locale) {
			commit(types.SET_LOCALE, locale);
		}
	},

	mutations: {
		[types.SET_LOCALE](state, value) {
			state.locale = value;
			setStorage('lang', value);
		}
	},
	namespaced: true
};
