<template>
	<div class="navigation" :class="currentClass">
		<router-link to="/"> <BackBtn /> </router-link>
		<div class="nav-line-menu">
			<router-link
				v-for="link in navLinks"
				:id="link.id"
				:key="link.route"
				:to="link.route"
				:class="link.id == current ? 'active' : ''"
			>
				{{ $t(link.name) }}
			</router-link>
		</div>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import BackBtn from '@/components/Button/Back';

export default {
	name: 'NavLine',
	components: {
		BackBtn
	},
	props: {
		current: {
			type: String,
			default: ''
		},
		currentClass: {
			type: String,
			default: ''
		}
	},
	setup() {
		const store = useStore();
		const navLinks = ref(store.getters['links/getNavigationLinks']);
		return {
			navLinks
		};
	},
	updated() {
		if (this.current !== 'main') {
			const line = document.querySelector('.navigation');
			line.style.animation = 'none';
			line.offsetHeight;
			line.style.animation = null;
		}
	}
};
</script>
<style lang="scss" scoped>
.navigation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 56px;
	margin-bottom: 25px;
	-webkit-animation: slideDown 0.8s linear forwards;
	animation: slideDown 0.8s linear forwards;
	svg.back {
		path {
			transition: 0.2s linear;
		}
	}
	svg.back:hover {
		path {
			fill: $color-green;
		}
	}
	@include laptop-standard {
		margin-top: 48px;
		margin-bottom: 20px;
	}
	@include ipad {
		display: none;
	}
	&.main {
		opacity: 0;
		z-index: -999;
	}
	&.aboutUs {
		margin-bottom: 69px;
	}
}
.nav-line-menu a {
	font-weight: 700;
	text-transform: uppercase;
	color: $color-light-purple;
	font-size: $small-L1;
	&:hover {
		color: $color-green;
	}
	position: relative;
	z-index: 1;
	padding-bottom: 5px;
	font-size: $standard-L2;
	font-weight: 600;
	&.active {
		color: $color-green;
		&::after {
			width: 100%;
		}
	}
	&:not(:last-child) {
		margin-right: 15px;
	}
	&::after {
		content: ' ';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 2px;
		border-radius: 2px;
		width: 0;
		transition: width 0.2s linear;
		background-color: $color-green;
	}
	&:hover {
		&::after {
			width: 100%;
		}
	}
}
</style>
