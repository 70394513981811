<template>
	<a
		:href="link.url"
		:title="$t(link.name)"
		class="btn"
		:class="checkLink(link.url) ? link.class : `btn ${link.class} disabled`"
		target="_blank"
		@click="onClickLink"
	>
		{{ $t(link.name) }}
	</a>
</template>
<script>
import { checkLink } from '@/validation/links';

export default {
	name: 'ButtonLink',
	components: {},
	props: {
		link: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	setup() {
		return {
			checkLink
		};
	},
	methods: {
		checkClasses(classList) {
			const NOT_ALLOW = ['disabled', 'coming-soon'];
			for (const className of classList) {
				if (NOT_ALLOW.includes(className)) return true;
			}
		},
		onClickLink(e) {
			if (this.checkClasses(e.target.classList))
				return e.preventDefault();
		}
	}
};
</script>
<style lang="scss" scoped></style>
