import i18JSON from '../../plugins/languages/en.json';
import { getListFromI18JSON } from '../../utils/json';

const PAGE_KEY = 'platformPage';
const CONTENT_ITEM_KEY = 'contentListItem';
const MOBILE_KEY = 'mobile';

const platformCL = getListFromI18JSON(
	i18JSON[PAGE_KEY],
	PAGE_KEY,
	CONTENT_ITEM_KEY
);

const mobileCL = getListFromI18JSON(
	i18JSON[PAGE_KEY][MOBILE_KEY],
	`${PAGE_KEY}.${MOBILE_KEY}`,
	CONTENT_ITEM_KEY
);

export default {
	state: () => ({
		platformCL: platformCL,
		features: [
			{
				key: 'tracker',
				name: 'buttons.tracker',
				description: 'platformPage.modal.tracker'
			},
			{
				key: 'transactions',
				name: 'buttons.transactions',
				description: 'platformPage.modal.transaction'
			},
			{
				key: 'sub-portfolios',
				name: 'buttons.sub-portfolios',
				description: 'platformPage.modal.sub-portfolios'
			},
			{
				key: 'multi-wallet',
				name: 'buttons.multi-wallet',
				description: 'platformPage.modal.multi-wallet'
			},
			{
				key: 'bridges',
				name: 'buttons.bridges',
				description: 'platformPage.modal.bridges'
			},
			{
				key: 'notifications',
				name: 'buttons.notifications',
				description: 'platformPage.modal.notifications'
			},
			{
				key: 'rewards',
				name: 'buttons.rewards',
				description: 'platformPage.modal.rewards'
			},
			{
				key: 'autostaking',
				name: 'buttons.autostaking',
				description: 'platformPage.modal.autostaking'
			}
		],
		mobileCL: mobileCL,
		statistics: [
			{
				key: 'delegation',
				number: '85M',
				content: 'Assets staked'
			},
			{
				key: 'supported',
				number: '41+',
				content: 'Networks supported'
			},
			{
				number: '24<span>/7</span>',
				content: 'User support',
				class: 'to-small'
			},
			{
				number: '9+',
				content: 'dApps in Citadel.one'
			}
		],
		mobileAppLinks: [
			{
				url: 'https://apps.apple.com/app/citadel-one/id1546701475',
				name: 'buttons.appStore',
				class: 'green'
			},
			{
				url: 'https://play.google.com/store/apps/details?id=one.citadel.mobile',
				name: 'buttons.googlePlay',
				class: 'dark-purple'
			}
		],
		manageLins: [
			{
				url: 'https://app.citadel.one',
				name: 'buttons.getStarted',
				class: 'green'
			},
			{
				url: 'https://medium.com/citadel-one',
				name: 'buttons.learnHow',
				class: ''
			}
		],
		banner: {
			img: `${require('@/assets/images/banners/banner1.png')}`,
			url: 'https://medium.com/citadel-one/autorestake-app-overview-ebefaecb38d2'
		}
	}),

	getters: {
		GET_PLATFORM_CL: (state) => state.platformCL,
		GET_PLATFORM_FEATURES: (state) => state.features,
		GET_PLATFORM_MOBILE_CL: (state) => state.mobileCL,
		GET_PLATFORM_MOBILE_LINKS: (state) => state.mobileAppLinks,
		GET_PLATFORM_MANAGE_LINKS: (state) => state.manageLins,
		GET_PLATFORM_STATISTICS: (state) => state.statistics,
		GET_PLATFORM_BANNER: (state) => state.banner
	},
	namespaced: true
};
