<template>
	<button id="burger-menu" class="burger-menu" @click="toggleMobile">
		<span class="burger"></span>
		<span class="burger"></span>
		<span class="burger"></span>
		<span class="close"></span>
		<span class="close"></span>
	</button>

	<div id="mobile-nav" :class="`${current} site-nav-mobile`">
		<div class="site-nav-mobile-top">
			<router-link to="/" @click="toggleMobile">
				<BackBtn />
			</router-link>
		</div>

		<div class="site-nav-mobile-wrap">
			<router-link
				v-for="link in links"
				:key="link"
				:to="link.route"
				class="site-nav-mobile-link"
				@click="toggleMobile"
			>
				{{ $t(link.name) }}
			</router-link>
		</div>
		<div class="site-nav-mobile-bottom">
			<a :href="guide" target="_blank" class="guides">
				{{ $t('buttons.guides') }}
			</a>

			<div class="site-nav-mobile-privacy-menu">
				<button
					class="text-link"
					name="Privacy Policy"
					@click="$parent.toggleModal('PrivacyModal')"
				>
					{{ $t('privacyPolicy') }}
				</button>
				<button
					class="text-link"
					name="Terms Of Service"
					@click="$parent.toggleModal('ServiceModal')"
				>
					{{ $t('termsOfService') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import store from '../store';
import BackBtn from '@/components/Button/Back';

export default {
	name: 'MobileNav',
	components: {
		BackBtn
	},
	props: {
		current: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			links: store.getters['links/getNavigationLinks'],
			guide: store.getters['links/getGuidesLink']
		};
	},
	methods: {
		toggleMobile() {
			const burger = document.querySelector('#burger-menu');
			const mobileNav = document.querySelector('#mobile-nav');

			if (burger.classList.contains('active')) {
				burger.classList.remove('active');
				return mobileNav.classList.remove('active');
			}
			burger.classList.add('active');
			return mobileNav.classList.add('active');
		}
	}
};
</script>

<style lang="scss" scoped>
.site-nav-mobile {
	background-color: $color-black;
	position: fixed;
	z-index: -999;
	opacity: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 25px 5% 35px;
	transition: 0.2s linear;
	&,
	&-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&.main {
		background-image: url('~@/assets/images/background/mobile-nav.png');
		background-repeat: no-repeat;
		background-position: center;
		background-position-y: 45%;
		background-size: 120px 120px;
		.back-btn {
			opacity: 0;
			visibility: hidden;
			z-index: -999;
		}
		.site-nav-mobile-wrap {
			opacity: 0;
			z-index: -999;
			visibility: hidden;
		}
	}
	&.active {
		z-index: 999;
		opacity: 1;
	}
	&-top {
		margin-bottom: auto;
		text-align: left;
		margin-right: auto;
	}

	&-link {
		color: $color-dark-purple;
		font-weight: 700;

		text-transform: uppercase;
		transition: 0.2s linear;
		word-break: keep-all;

		height: fit-content;

		max-height: 70px;
		font-size: $medium-L1;

		&:hover {
			color: $color-white;
		}
	}
	&-bottom {
		margin-top: auto;
		width: 100%;
		text-align: center;
		.guides {
			margin-right: 0;
		}
	}

	&-privacy-menu {
		margin-top: 25px;
		padding-top: 25px;
		border-top: 1px dashed $color-purple;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}
.burger-menu {
	cursor: pointer;
	display: none;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	background: none;
	border: none;
	outline: none;
	width: 56px;
	height: 32px;
	min-width: 56px;
	min-height: 32px;
	max-width: 56px;
	max-height: 32px;
	overflow: hidden;
	& > span {
		display: block;
		height: 5px !important;
		width: 100%;
		max-width: 56px;
		border-radius: 2px;
		background: $color-green;
		transition: 0.2s linear;
		&.burger:not(:last-child) {
			margin-bottom: 5px;
		}
		&.burger:nth-child(3) {
			width: 80%;
		}
		&.burger:nth-child(1) {
			width: 50%;
		}
		&.close {
			position: absolute;
			top: 45%;
			left: 0;
			right: 0;
			margin: 0 3px 0 auto;
			width: 70% !important;
			transform: scale(0);
		}
	}
	&:hover {
		& > span {
			background: $color-purple;
			&.burger:nth-child(1) {
				width: 100%;
			}
			&.burger:nth-child(2) {
				width: 80%;
			}
			&.burger:nth-child(3) {
				width: 50%;
			}
		}
	}
	@include ipad {
		animation: showItem 1.1s forwards;
		opacity: 1;
		display: flex;
		position: fixed;
		z-index: 1000;
		top: 25px;
		right: 5%;
		&.active {
			& > span {
				&.burger {
					transform: scale(0);
					opacity: 0;
				}
				&.close:nth-child(4) {
					transform: scale(1) rotate(45deg);
				}
				&.close:nth-child(5) {
					transform: scale(1) rotate(-45deg);
				}
			}
		}
	}
}
</style>
