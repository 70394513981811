<template>
	<vue-final-modal
		v-slot="{ close }"
		:name="targetModal"
		v-bind="$attrs"
		classes="modal-container"
		content-class="modal-content"
	>
		<span class="modal__title">
			{{ $t(targetModal) }}
		</span>
		<div class="modal__content">
			<component :is="content"></component>
		</div>
		<CloseIcon class="modal__close" @click="close" />
	</vue-final-modal>
</template>
<script>
import CloseIcon from '@/assets/icons/close.svg';
import { ref, markRaw } from 'vue';

export default {
	name: 'DynamicModal',
	components: {
		CloseIcon
	},
	inheritAttrs: false,
	props: {
		targetModal: {
			type: String,
			default: ''
		}
	},
	setup(props) {
		const content = ref();
		import(`@/components/Modals/${props.targetModal}.vue`).then((val) => {
			content.value = markRaw(val.default);
		});
		return {
			content
		};
	},
	data() {
		return {
			show: false
		};
	},
	mounted() {
		this.$vfm.show(this.targetModal);
	}
};
</script>
<style lang=""></style>
