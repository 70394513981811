export default {
	state: () => ({
		partnersLight: [
			'Secret',
			'Akash',
			'Injective',
			'Staking-Rewards',
			'Regen',
			'IOST',
			'FetchAi',
			'Sifchain',
			'Umee',
			'Bitsong',
			'Cheqd'
		],
		allPartners: [
			{ name: 'Axelar' },
			{ name: 'Akash' },
			{ name: 'Archway' },
			{ name: 'AngelTalks' },
			{ name: 'Bitsong' },
			{ name: 'BanklessUA' },
			{ name: 'Coingecko' },
			{ name: 'Crescent' },
			{ name: 'CSP DAO' },
			{ name: 'Comdex' },
			{ name: 'CryptoLodes' },
			{ name: 'DeFund' },
			{ name: 'DCI Capital' },
			{ name: 'e-Money' },
			{ name: 'Gravity Bridge' },
			{ name: 'HG Ventures' },
			{ name: 'IRIS' },
			{ name: 'IOST' },
			{ name: 'Jackal' },
			{ name: 'Kyve' },
			{ name: 'Konstellation' },
			{ name: 'Kava' },
			{ name: 'MastersVentures' },
			{ name: 'Map Of Zones' },
			{ name: 'Oasis' },
			{ name: 'Osmosis' },
			{ name: 'Persistence' },
			{ name: 'Imperator-co' },
			{ name: 'POSTHUMAN' },
			{ name: 'Panda Capital' },
			{ name: 'Quicksilver' },
			{ name: 'Rizon' },
			{ name: 'Regen' },
			{ name: 'Secret Network' },
			{ name: 'Sifchain' },
			{ name: 'Starname' },
			{ name: 'Staking Rewards' },
			{ name: 'SpaceFi' },
			{ name: 'Titans Ventures' },
			{ name: 'Umee' },
			{ name: 'Wanchain' }
		].map((partner) => {
			partner.description = `aboutUsPage.partners.partnersList.${partner.name}.description`;
			partner.type = `aboutUsPage.partners.partnersList.${partner.name}.type`;
			partner.imageName = partner.name.split(' ').join('-');
			return partner;
		}),
		partnersFilter: ['service', 'investment', 'media', 'ecosystem']
	}),
	getters: {
		getPartnersLight: (state) => state.partnersLight,
		getAllPartners: (state) => state.allPartners,
		getPartnersFilter: (state) => state.partnersFilter
	},
	namespaced: true
};
