import axios from 'axios';
const API = process.env.VUE_APP_BACKEND_URL;

export default {
	state: () => ({
		socials: [
			{
				name: 'twitter',
				link: 'http://link.citadel.one/twitter_land',
				icon: require('@/assets/images/subscribes/twitter.svg'),
				size: [25, 21]
			},
			{
				name: 'facebook',
				link: 'http://link.citadel.one/facebook_land',
				icon: require('@/assets/images/subscribes/facebook.svg'),
				size: [14, 26]
			},
			{
				name: 'telegram',
				link: 'http://link.citadel.one/telegram_land',
				icon: require('@/assets/images/subscribes/telegram.svg'),
				size: [22, 19]
			},
			{
				name: 'github',
				link: 'http://link.citadel.one/github_land',
				icon: require('@/assets/images/subscribes/github.svg'),
				size: [25, 25]
			},
			{
				name: 'reddit',
				link: 'http://link.citadel.one/reddit_land',
				icon: require('@/assets/images/subscribes/reddit.svg'),
				size: [25, 25]
			},
			{
				name: 'medium',
				link: 'http://link.citadel.one/medium_land',
				icon: require('@/assets/images/subscribes/medium.svg'),
				size: [25, 19]
			},
			{
				name: 'instagram',
				link: 'http://link.citadel.one/instagram_land',
				icon: require('@/assets/images/subscribes/instagram.svg'),
				size: [22, 23]
			},
			{
				name: 'youtube',
				link: 'http://link.citadel.one/youtube_land',
				icon: require('@/assets/images/subscribes/youtube.svg'),
				size: [28, 19]
			},
			{
				name: 'linkedin',
				link: 'http://link.citadel.one/linkedin_land',
				icon: require('@/assets/images/subscribes/linkedin.svg'),
				size: [22, 22]
			},
			{
				name: 'weibo',
				link: 'https://weibo.com/citadelone',
				icon: require('@/assets/images/subscribes/weibo.svg'),
				size: [35, 29]
			},
			{
				name: 'discord',
				link: 'https://discord.gg/jtKvxxjMc9',
				icon: require('@/assets/images/subscribes/discord.svg'),
				size: [34, 23]
			}
		]
	}),

	getters: {
		getSocialLinks: (state) => state.socials
	},

	actions: {
		async ASK_QUESTION(_, { name, email, comment = '' }) {
			try {
				const { data } = await axios.post(`${API}/feedback`, {
					name,
					email,
					comment
				});
				if (!data.ok) {
					return;
				}
				return { data: data.ok, error: null };
			} catch (error) {
				return {
					data: null,
					error: error.response && error.response.data.error
				};
			}
		}
	},
	namespaced: true
};
