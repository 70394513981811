import { createRouter, createWebHistory } from 'vue-router';
import ROUTES from '@/store/routes';

const routes = [
	{
		path: ROUTES.MAIN_ROUTE,
		name: 'home',
		component: () => import('../pages/Home.vue')
	},
	{
		path: ROUTES.PLATFORM_ROUTE,
		name: 'platform',
		component: () => import('../pages/Platform.vue')
	},
	{
		path: ROUTES.STAKING_ROUTE,
		name: 'staking',
		component: () => import('../pages/Staking.vue')
	},
	{
		path: ROUTES.ABOUT_US_ROUTE,
		name: 'about',
		component: () => import('../pages/About.vue')
	},
	{
		path: ROUTES.XCT_ROUTE,
		name: 'xct',
		component: () => import('../pages/XCT.vue')
	},
	{
		path: ROUTES.EXTENSIONS_ROUTE,
		name: 'extensions',
		component: () => import('../pages/Extensions.vue')
	},
	{
		path: ROUTES.TERMS,
		component: () => import('../pages/Terms.vue')
	},
	{
		path: ROUTES.PRIVACY,
		component: () => import('../pages/Privacy.vue')
	},
	{
		path: ROUTES.DAO_ROUTE,
		redirect: '/xct'
	},
	{
		path: ROUTES.DOCS_ROUTE,
		redirect: '/xct'
	},
	{
		path: '/about-us',
		redirect: '/about'
	},
	{
		path: '/:catchAll(.*)',
		component: () => import('../components/Page_404.vue')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior() {
		return {
			top: 0
		};
	},
	routes
});

export default router;
