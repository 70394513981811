<template>
	<svg
		width="224"
		height="32"
		viewBox="0 0 224 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M43.721 23.7582C41.6552 21.7653 40.6489 19.2337 40.6489 16.2174C40.6489 13.255 41.6552 10.7235 43.721 8.73058C45.7867 6.73768 48.3821 5.71429 51.4012 5.71429C54.1555 5.71429 56.539 6.57609 58.4988 8.24582C60.5115 9.91555 61.6768 12.07 62.1005 14.6016H58.3928C58.0221 12.9857 57.2276 11.6391 55.9564 10.6158C54.6852 9.59238 53.1491 9.10762 51.4012 9.10762C49.3884 9.10762 47.6935 9.80783 46.3163 11.1544C44.9392 12.5009 44.2506 14.2245 44.2506 16.2174C44.2506 18.2103 44.9392 19.9339 46.3163 21.2805C47.6935 22.627 49.3884 23.3273 51.4012 23.3273C53.1491 23.3273 54.6322 22.7886 55.9564 21.7653C57.2276 20.7419 58.075 19.4492 58.3928 17.8333H62.1005C61.7298 20.4187 60.5115 22.5193 58.4988 24.1891C56.486 25.8588 54.1555 26.7206 51.4012 26.7206C48.3821 26.7744 45.7867 25.8049 43.721 23.7582Z"
			fill="white"
		/>
		<path
			d="M65.3845 26.4512V6.09131H68.9333V26.4512H65.3845Z"
			fill="white"
		/>
		<path
			d="M86.5713 6.09131V9.37691H81.0098V26.4512H77.5139V9.37691H71.9524V6.09131H86.5713Z"
			fill="white"
		/>
		<path
			d="M101.614 26.4512L99.972 22.1423H90.6498L88.9549 26.4512H85.1412L93.2452 6.09131H97.4296L105.534 26.4512H101.614ZM91.8681 18.8567H98.7008L95.258 9.91553L91.8681 18.8567Z"
			fill="white"
		/>
		<path
			d="M116.18 6.09131C119.199 6.09131 121.636 7.06083 123.648 8.99987C125.661 10.9389 126.614 13.3627 126.614 16.2713C126.614 19.1798 125.608 21.6036 123.648 23.5427C121.636 25.4817 119.146 26.4512 116.18 26.4512H108.235V6.09131H116.18ZM116.127 23.1118C118.087 23.1118 119.729 22.4654 121.053 21.1189C122.377 19.8262 123.013 18.1565 123.013 16.2174C123.013 14.2784 122.377 12.6086 121.053 11.3159C119.729 10.0233 118.087 9.37691 116.127 9.37691H111.731V23.1118H116.127Z"
			fill="white"
		/>
		<path
			d="M133.553 23.1118H141.763V26.3974H130.004V6.09131H141.604V9.37691H133.553V14.5477H140.598V17.8333H133.553V23.1118Z"
			fill="white"
		/>
		<path
			d="M148.702 23.1118H156.011V26.3974H145.206V6.09131H148.755V23.1118H148.702Z"
			fill="white"
		/>
		<path
			d="M160.778 26.6667C160.09 26.6667 159.507 26.4513 159.083 26.0204C158.66 25.5895 158.395 25.0509 158.395 24.3507C158.395 23.7043 158.607 23.1118 159.083 22.6809C159.56 22.25 160.09 21.9807 160.778 21.9807C161.467 21.9807 162.049 22.1962 162.473 22.6809C162.95 23.1118 163.162 23.7043 163.162 24.3507C163.162 25.0509 162.95 25.5895 162.473 26.0204C162.049 26.4513 161.467 26.6667 160.778 26.6667Z"
			fill="white"
		/>
		<path
			d="M175.45 26.7744C172.378 26.7744 169.783 25.7511 167.717 23.7582C165.651 21.7653 164.645 19.2337 164.645 16.2174C164.645 13.255 165.651 10.7235 167.717 8.73058C169.783 6.73768 172.378 5.71429 175.45 5.71429C178.522 5.71429 181.118 6.73768 183.13 8.73058C185.196 10.7235 186.202 13.255 186.202 16.2174C186.202 19.2337 185.196 21.7114 183.13 23.7582C181.118 25.8049 178.522 26.7744 175.45 26.7744ZM180.588 21.3344C181.965 19.9878 182.654 18.2642 182.654 16.2713C182.654 14.2784 181.965 12.5548 180.588 11.2082C179.211 9.86169 177.516 9.16148 175.45 9.16148C173.437 9.16148 171.742 9.86169 170.365 11.2082C168.988 12.5548 168.3 14.2784 168.3 16.2713C168.3 18.2642 168.988 19.9878 170.365 21.3344C171.742 22.6809 173.437 23.3811 175.45 23.3811C177.516 23.3811 179.211 22.6809 180.588 21.3344Z"
			fill="white"
		/>
		<path
			d="M203.523 6.09131H207.072V26.4512H203.47L193.194 12.07V26.4512H189.645V6.09131H193.247L203.523 20.4725V6.09131Z"
			fill="white"
		/>
		<path
			d="M215.07 23.1118H223.279V26.3974H211.521V6.09131H223.068V9.37691H215.017V14.5477H222.061V17.8333H215.017V23.1118H215.07Z"
			fill="white"
		/>
		<path
			d="M16.1481 1.73684C22.2554 1.73684 27.4311 5.73684 29.3461 11.2632C29.5532 11.9474 30.1225 12.4737 30.8471 12.4737C31.5717 12.4737 32.2963 12.1579 32.2963 11.2632V2.15789C32.2963 0.947368 31.3129 0 30.1742 0H2.12203C0.98338 0 0 1 0 2.15789V29.7895C0 31 0.98338 31.9474 2.12203 31.9474H30.1225C31.3129 31.9474 32.2445 30.9474 32.2445 29.7895V20.6842C32.2445 19.8421 31.6752 19.2105 30.8471 19.2105C30.019 19.2105 29.5014 19.8421 29.2426 20.6842C27.4311 26.2632 22.2554 30.2632 16.1481 30.2632C8.38461 30.2632 2.12203 23.8947 2.12203 16C2.12203 8.10526 8.38461 1.73684 16.1481 1.73684Z"
			fill="white"
		/>
	</svg>
</template>
<script>
export default {
	name: 'CitadelLogo'
};
</script>
<style lang="scss" scoped>
svg {
	width: 100%;

	width: 224px;
	height: 32px;
	@include ipad {
		width: 168px;
		height: 24px;
	}
}
</style>
