<template>
	<div class="back-btn">
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.2415 11.001H11.5863V21.001M11.5863 11.001L23.1725 23.001L11.5863 11.001Z"
				stroke="#7D61ED"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M14 1.52224C19.2949 1.52224 23.7821 5.02801 25.4423 9.8715C25.6218 10.4712 26.1154 10.9325 26.7436 10.9325C27.3718 10.9325 28 10.6557 28 9.8715V1.89127C28 0.830313 27.1474 0 26.1603 0H1.83974C0.852564 0 0 0.876441 0 1.89127V26.1087C0 27.1697 0.852564 28 1.83974 28H26.1154C27.1474 28 27.9551 27.1236 27.9551 26.1087V18.1285C27.9551 17.3904 27.4615 16.8369 26.7436 16.8369C26.0256 16.8369 25.5769 17.3904 25.3526 18.1285C23.7821 23.0181 19.2949 26.5239 14 26.5239C7.26923 26.5239 1.83974 20.9423 1.83974 14.0231C1.83974 7.10379 7.26923 1.52224 14 1.52224Z"
				fill="#7D61ED"
			/>
		</svg>

		<span>{{ $t('buttons.back') }}</span>
	</div>
</template>
<script>
export default {
	name: 'BackBtn'
};
</script>
<style lang="scss" scoped>
.back-btn {
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: 0.2s linear;

	span {
		cursor: pointer;
		color: $color-light-purple;
		font-size: $standard-L2;
		text-transform: uppercase;
		font-weight: 600;
		line-height: 1 !important;
		margin-bottom: -3px;
		margin-left: 10px;
	}
	svg,
	svg > path,
	span {
		transition: 0.2s linear;
	}

	&:hover {
		span {
			color: $color-green;
		}
		svg > path:first-child {
			stroke: $color-green;
		}
		svg > path:nth-child(2) {
			fill: $color-green;
		}
	}
}
</style>
