import { createApp } from 'vue';
import VueFinalModal from 'vue-final-modal';
import { VueWindowSizePlugin } from 'vue-window-size/option-api';
import VueGtag from 'vue-gtag-next';

import jQuery from 'jquery';
import anime from 'animejs/lib/anime.min.js';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import { i18n } from '@/plugins/i18n';
import { openInNewTab } from '@/utils/link';

const $ = jQuery;
window.$ = $;

import '@/assets/scss/style.scss';

import 'swiper/css';
import 'swiper/css/navigation';

// localStorage.getItem('lang') || localStorage.setItem('lang', 'en');
const app = createApp(App);

app.config.globalProperties.openInNewTab = openInNewTab;
app.config.globalProperties.$anime = anime;

if (process.env.NODE_ENV === 'production') {
	app.use(VueGtag, {
		property: {
			id: 'G-C67NFF7XDJ'
		}
	});
}

app.use(store)
	.use(router)
	.use(i18n)
	.use(VueFinalModal())
	.use(VueWindowSizePlugin)
	.mount('#app');
