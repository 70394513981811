/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import axios from 'axios';
import BigNumber from 'bignumber.js';

const API = process.env.VUE_APP_BACKEND_URL;

export default {
	state: () => ({
		xct: {},
		totalSupply: 0,
		price: null,
		priceHistory: null,
		pricePerUSDT: null,
		networks: [],
		initialNet: {},
		supportedNetworks: [],
		contentList: [
			'xctPage.dao.contentListItem1',
			'xctPage.dao.contentListItem2',
			'xctPage.dao.contentListItem3',
			'xctPage.dao.contentListItem4',
			'xctPage.dao.contentListItem5'
		],
		delegationsStats: 80,
		delegationsStatsInterval: null
	}),

	getters: {
		getXCTReward: (state) => state.pricePerUSDT,
		getNetworks: (state) => state.networks,
		getInitialNet: (state) => state.initialNet,
		getXCTPrice: (state) => state.price,
		getXCTPriceHistory: (state) => state.priceHistory,
		getXCTDaoCL: (state) => state.contentList,
		GET_DELEGATIONS_STATS: (state) => state.delegationsStats
	},

	mutations: {
		SET_XCT_INFO(state, payload) {
			return (state.xct = payload);
		},
		SET_XCT_SUPPLY(state, payload) {
			return (state.totalSupply = payload);
		},
		SET_XCT_REWARD(state, payload) {
			return (state.pricePerUSDT = payload);
		},
		SET_NETWORKS(state, payload) {
			return (state.networks = payload);
		},
		SET_INITIAL_NETWORK(state, payload) {
			return (state.initialNet = payload);
		},
		SET_XCT_SUPPORTED_NETWORKS(state, payload) {
			return (state.supportedNetworks = payload);
		},
		SET_XCT_PRICE_HISTORY(state, payload) {
			return (state.priceHistory = payload);
		},
		SET_XCT_PRICE(state, payload) {
			return (state.price = payload);
		},
		SET_DELEGATIONS_STATS(state, payload) {
			return (state.delegationsStats = payload);
		},
		CLEAR_DELEGATION_STATS_INTERVAL(state) {
			state.delegationsStatsInterval = null;
		}
	},
	actions: {
		async PULL_CURRENCIES_PRICE({ commit, state, dispatch }) {
			try {
				await dispatch('XCT_SUPPORTED_NETWORKS');

				const { data } = await axios.get(`${API}/currency`);

				if (!data?.ok) {
					return;
				}

				const initialNet = data.data['cosmos'];

				commit('SET_INITIAL_NETWORK', initialNet);

				const networks = Object.values(data.data)
					.map((network) => network)
					.filter((network) =>
						state.supportedNetworks.includes(network.net)
					);

				commit('SET_NETWORKS', networks);
			} catch (error) {
				console.log(error);
			}
		},
		async GET_COIN_MARKETCAP({ _ }, coin) {
			try {
				const { data } = await axios.get(
					`${API}/currency/${coin}/marketcap`
				);

				if (!data?.ok) {
					return;
				}
				return data.data;
			} catch (error) {
				console.log(error);
			}
		},

		async XCT_INFO({ commit }) {
			try {
				const { data } = await axios.get(
					`${API}/blockchain/bsc_xct/0x0000000000000000000000000000000000000000/inflation`
				);
				if (!data?.ok) {
					return;
				}

				commit('SET_XCT_INFO', data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async XCT_SUPPORTED_NETWORKS({ commit }) {
			try {
				const { data } = await axios.get(
					`${API}/dao/supported_networks`
				);
				if (!data?.ok) {
					return;
				}

				commit('SET_XCT_SUPPORTED_NETWORKS', data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async XCT_NODES_SUPPLY({ commit }) {
			try {
				const { data } = await axios.get(`${API}/dao/total_supply`);

				if (!data.ok) {
					return;
				}

				commit('SET_XCT_SUPPLY', data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async XCT_REWARD({ commit, state, dispatch }) {
			try {
				await dispatch('XCT_INFO');
				await dispatch('XCT_NODES_SUPPLY');

				const rewardsPerUsdt = BigNumber(state.xct.yearlySupply)
					.times(state.xct.inflationPct)
					.times(state.xct.stakingNodesPct)
					.div(10000)
					.div(state.totalSupply);

				commit('SET_XCT_REWARD', rewardsPerUsdt);
			} catch (error) {
				console.log(error);
			}
		},

		async XCT_PRICE_HISTORY({ commit }) {
			try {
				const currentTime = new Date();
				const prevPeriod = new Date();

				prevPeriod.setDate(currentTime.getDate() - 7);

				const { data } = await axios.get(
					`${API}/currency/history?net=bsc_xct&fiat=usd&from_date=${prevPeriod.getTime()}&to_date=${currentTime.getTime()}`
				);

				if (!data.ok) {
					return;
				}

				commit('SET_XCT_PRICE_HISTORY', data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async XCT_PRICE({ commit }) {
			try {
				const { data } = await axios.get(`${API}/currency/bsc_xct`);

				if (!data.ok) {
					return;
				}
				commit(
					'SET_XCT_PRICE',
					new Intl.NumberFormat().format(data.data.USD)
				);
			} catch (error) {
				console.log(error);
			}
		},

		async PULL_DELEGATIONS_STATS({ commit }) {
			try {
				const { data } = await axios.get(`${API}/delegations-stats`);
				if (!data?.ok || Number.isNaN(+data?.data?.amount)) {
					return;
				}
				commit('SET_DELEGATIONS_STATS', +data.data.amount);
			} catch (error) {
				console.log(error);
			}
		},

		RUN_DELEGATION_STATS_INTERVAL({ state, dispatch }) {
			state.delegationsStatsInterval = setInterval(() => {
				dispatch('PULL_DELEGATIONS_STATS');
			}, 60 * 1000);
		},

		CLEAR_DELEGATION_STATS_INTERVAL({ commit }) {
			commit('CLEAR_DELEGATION_STATS_INTERVAL');
		}
	},

	namespaced: true
};
