import i18JSON from '../../plugins/languages/en.json';
import { getListFromI18JSON } from '../../utils/json';

const PAGE_KEY = 'stakingPage';
const VALIDATOR_KEY = 'validator';
const CONTENT_ITEM_KEY = 'contentListItem';

const validatorCL = getListFromI18JSON(
	i18JSON[PAGE_KEY][VALIDATOR_KEY],
	`${PAGE_KEY}.${VALIDATOR_KEY}`,
	CONTENT_ITEM_KEY
);

export default {
	state: () => ({
		validatorCL: validatorCL,
		statistics: [
			{
				number: '5%',
				content: 'Standard commission'
			},
			{
				key: 'delegation',
				number: '55',
				content: 'Staking TVL'
			},
			{
				number: '99%',
				content: 'Overall uptime'
			},
			{
				key: 'validated',
				number: '47+',
				content: 'Networks validated'
			}
		],
		manageList: [
			{
				number: 1,
				title: 'stakingPage.governance.manageGovernance.manage1.title',
				content:
					'stakingPage.governance.manageGovernance.manage1.description'
			},
			{
				number: 2,
				title: 'stakingPage.governance.manageGovernance.manage2.title',
				content:
					'stakingPage.governance.manageGovernance.manage2.description'
			},
			{
				number: 3,
				title: 'stakingPage.governance.manageGovernance.manage3.title',
				content:
					'stakingPage.governance.manageGovernance.manage3.description'
			}
		]
	}),

	getters: {
		getValidatorCL: (state) => state.validatorCL,
		getManageList: (state) => state.manageList,
		GET_STAKING_STATISTICS: (state) => state.statistics
	},
	namespaced: true
};
