const XCT_ROUTE = '/xct';
const STAKING_ROUTE = '/staking';
const PLATFORM_ROUTE = '/platform';
const EXTENSIONS_ROUTE = '/extensions';
const ABOUT_US_ROUTE = '/about';

export default {
	state: () => ({
		mainLinks: [
			{
				id: 'xct',
				route: XCT_ROUTE,
				name: 'xct'
			},
			{
				id: 'staking',
				route: STAKING_ROUTE,
				name: 'staking'
			},
			{
				id: 'platform',
				route: PLATFORM_ROUTE,
				name: 'platform'
			},
			{
				id: 'extensions',
				route: EXTENSIONS_ROUTE,
				name: 'extensions'
			},
			{
				id: 'aboutUs',
				route: ABOUT_US_ROUTE,
				name: 'aboutUs'
			}
		],
		navigationLinks: [
			{
				id: 'staking',
				route: STAKING_ROUTE,
				name: 'staking'
			},
			{
				id: 'platform',
				route: PLATFORM_ROUTE,
				name: 'platform'
			},
			{
				id: 'xct',
				route: XCT_ROUTE,
				name: 'xct'
			},
			{
				id: 'extensions',
				route: EXTENSIONS_ROUTE,
				name: 'extensions'
			},
			{
				id: 'aboutUs',
				route: ABOUT_US_ROUTE,
				name: 'aboutUs'
			}
		],

		community: [
			{
				country: 'CitadelOne',
				url: 'https://t.me/citadelofficial'
			},
			{
				country: 'Japan',
				url: 'https://t.me/citadel_japan'
			},
			{
				country: 'Korea',
				url: 'https://t.me/citadel_kor'
			},
			{
				country: 'Vietnam',
				url: 'https://t.me/citadel_VN'
			},
			{
				country: 'Russia',
				url: 'https://t.me/citadel_rus'
			},
			{
				country: 'Indonesia',
				url: 'https://t.me/citadel_zh'
			},
			{
				country: 'China',
				url: 'https://t.me/citadel_zh'
			},
			{
				country: 'Spain',
				url: 'https://t.me/citadel_esp'
			},
			{
				country: 'France',
				url: 'https://t.me/citadel_fr'
			}
		],
		citadelPlatform: 'https://app.citadel.one',
		platformLink: {
			url: 'https://app.citadel.one',
			name: 'buttons.platform',
			class: 'btn'
		},
		guidesLink: 'https://medium.com/citadel-one',
		learnHow: {
			url: 'https://medium.com/citadel-one',
			name: 'buttons.learnHow'
		},
		browserExtension: {
			url: 'coming-soon',
			name: 'buttons.comingSoon',
			class: 'coming-soon'
		},

		stakingGroupLinks: [
			{
				url: 'https://t.me/citadelvotebot',
				name: 'buttons.governanceBot',
				class: 'purple btn-w-200'
			},
			{
				url: '#',
				name: 'buttons.validatorConstitution',
				class: 'disabled btn-w-260'
			},
			{
				url: 'https://discord.gg/jtKvxxjMc9',
				name: 'buttons.forum',
				class: 'green btn-w-200'
			}
		],

		aboutUsGroupLinks: [
			{
				url: 'https://t.me/citadelofficial',
				class: 'purple',
				name: 'buttons.telegram'
			},
			{
				url: 'mailto: strategy@citadel.one',
				class: 'green',
				name: 'buttons.email'
			},
			{
				url: 'https://twitter.com/CitadelDAO',
				class: '',
				name: 'buttons.twitter'
			}
		],

		xctGroupLinks: [
			{
				url: 'https://app.citadel.one',
				name: 'buttons.earn',
				class: 'purple'
			},
			{
				url: 'https://www.mexc.com/exchange/XCT_USDT',
				name: 'buttons.buy',
				class: ''
			},
			{
				url: 'https://pancakeswap.finance/info/tokens/0xe8670901e86818745b28c8b30b17986958fce8cc',
				name: 'buttons.swap',
				class: 'green'
			}
		],
		xctTokenomicsLinks: [
			{
				url: 'https://docsend.com/view/x95qn4aetp2pc8jp',
				name: 'buttons.fullVersion',
				class: ''
			},
			{
				url: 'https://docsend.com/view/rhu2aenbkxv366ms',
				name: 'buttons.whitepaper',
				class: 'green'
			}
		],
		xctUseCaseLinks: [
			{
				url: 'http://youtu.be/RUKjuG-xI88',
				icon: 'xctPage.useCase.case1.icon',
				title: 'xctPage.useCase.case1.title',
				description: 'xctPage.useCase.case1.description',
				class: ''
			},
			{
				url: 'https://medium.com/citadel-one/xct-token-staking-guide-on-citadel-one-web-app-d96dc8da97df',
				icon: 'xctPage.useCase.case2.icon',
				title: 'xctPage.useCase.case2.title',
				description: 'xctPage.useCase.case2.description',
				class: ''
			},
			{
				url: '#',
				icon: 'xctPage.useCase.case3.icon',
				title: 'xctPage.useCase.case3.title',
				description: 'xctPage.useCase.case3.description',
				class: 'disabled'
			}
		],
		xctExplorer: 'https://www.coingecko.com/en/coins/citadel-one'
	}),

	getters: {
		getMainLinks: (state) => state.mainLinks,
		getNavigationLinks: (state) => state.navigationLinks,
		getPlatformLinks: (state) => state.platformLink,

		getLearnHow: (state) => state.learnHow,
		getBrowserExtension: (state) => state.browserExtension,

		getCommunities: (state) => state.community,
		getFeatureLinks: (state) => state.featureLinks,

		getCitadelPlatformLink: (state) => state.citadelPlatform,

		getGuidesLink: (state) => state.guidesLink,

		getStakingGroupLinks: (state) => state.stakingGroupLinks,

		getAboutUsGroupLinks: (state) => state.aboutUsGroupLinks,

		getXctGroupLinks: (state) => state.xctGroupLinks,
		getXctTokenomicsLinks: (state) => state.xctTokenomicsLinks,
		getXctUseCaseLinks: (state) => state.xctUseCaseLinks,
		getXctExplorer: (state) => state.xctExplorer
	},
	namespaced: true
};
