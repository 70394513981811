<template>
	<footer class="footer container max-width">
		<p class="copyright">
			© {{ new Date().getFullYear() }}. {{ $t('allRights') }}
		</p>

		<!-- <div class="footer-lang-menu">
			<a
				v-for="(item, key) in languages"
				:key="key"
				:class="{ active: item.key == currentLanguage }"
				@click="changeLanguage(item.key)"
			>
				{{ item.label }}
			</a> </div> -->
		<div class="footer-privacy-term-menu">
			<button
				class="text-link"
				name="Privacy Policy"
				@click="$parent.toggleModal('PrivacyModal')"
			>
				{{ $t('privacyPolicy') }}
			</button>
			<button
				class="text-link"
				name="Terms Of Service"
				@click="$parent.toggleModal('ServiceModal')"
			>
				{{ $t('termsOfService') }}
			</button>
		</div>
	</footer>
</template>

<script>
import store from '../store';

export default {
	name: 'Footer',
	data() {
		return {
			currentLanguage: store.getters['i18n/locale'],
			languages: store.getters['i18n/locales']
		};
	},
	methods: {
		changeLanguage(value) {
			store.dispatch('i18n/setLocale', value);
			window.location.reload();
		}
	}
};
</script>

<style lang="scss" scoped>
.footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 62px;

	transform: translateY(-100%);
	animation: footerSlideUp 1s forwards;

	@include ipad {
		padding-bottom: 20px;
	}
	&-lang-menu {
		width: fit-content;

		a {
			font-weight: 600;
			transition: 0.4s ease-in;

			:hover {
				color: $color-green;
			}

			&.active {
				color: $color-green;
			}
		}

		a:not(:last-child) {
			margin-right: 10px;
		}

		position: absolute;
		right: 0;
		left: 0;
		margin: auto;

		@include ipad {
			bottom: 60px;
		}
	}
}

.footer,
.footer-lang-menu,
.footer-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.copyright {
	margin: 0;
}

.footer a,
.copyright,
.footer-lang-menu a {
	color: $color-light-purple;
	font-size: $small-L1;
	@include laptop-sm {
		font-size: $small-L2;
	}
}

.footer a,
.footer .text-link {
	font-weight: 700;
	text-transform: uppercase;
	transition: 0.2s;
	cursor: pointer;
	@include laptop-sm {
		font-size: $small-L2;
	}
}

.footer a:hover {
	color: $color-green;
}

.footer-menu > a:not(:last-child) {
	margin-right: 15px;
}

@include mobile {
	.footer {
		justify-content: center;
	}
	.footer__link {
		display: none;
	}
	.footer-privacy-term-menu {
		display: none;
	}
}

@keyframes footerSlideUp {
	to {
		transform: translateY(0);
	}
}
</style>
