<template>
	<div id="root" class="wrap" :class="`${bgClass} ${current}`">
		<Header :current="current" />

		<NavLine :current-class="`${current} container`" :current="current" />

		<MobileNav :current="current" />

		<div class="page-wrap">
			<router-view
				@background-off="(val) => (bgClass = val)"
				@current-nav-line="(val) => (current = val)"
			/>
		</div>

		<Footer />

		<DynamicModal
			v-if="targetModal"
			v-model="show"
			:target-modal="targetModal"
			@click-outside="closeModal"
			@closed="closeModal"
		>
		</DynamicModal>
		<div id="custom-cursor">
			<div class="inn"></div>
		</div>
		<div class="tooltip-content"></div>
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import MobileNav from '@/components/MobileNav.vue';
import NavLine from '@/components/NavLine.vue';

import Footer from '@/components/Footer.vue';
import DynamicModal from '@/components/Modals/Modal';

export default {
	name: 'App',

	components: {
		Header,
		MobileNav,
		NavLine,
		Footer,
		DynamicModal
	},
	emits: ['current-nav-line', 'background-off', 'toggle-modal'],
	setup() {
		const API = process.env.VUE_APP_BACKEND_URL;

		if (process.env.VUE_APP_ANALYTICS === 'on') {
			const mixpanel = require('mixpanel-browser');
			mixpanel.init('cfbf971e34397d01fa917cbd4044419d', {
				batch_requests: true,
				api_host: `${API}/webhooks/mixpanel`,
				ignore_dnt: true
			});
			window.mixpanel = mixpanel;

			/* eslint-disable */
			const getQueryParam = (url, param) => {
				// Expects a raw URL
				param = param.replace(/[[]/, '\[').replace(/[]]/, '\]');
				var regexS = '[\?&]' + param + '=([^&#]*)',
					regex = new RegExp(regexS),
					results = regex.exec(url);
				if (
					results === null ||
					(results &&
						typeof results[1] !== 'string' &&
						results[1].length)
				) {
					return '';
				} else {
					return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
				}
			};

			const campaignParams = () => {
				var campaign_keywords =
						'utm_source utm_medium utm_campaign utm_content utm_term'.split(
							' '
						),
					kw = '',
					params = {},
					first_params = {};
				var index;
				for (index = 0; index < campaign_keywords.length; ++index) {
					kw = getQueryParam(document.URL, campaign_keywords[index]);
					if (kw.length) {
						params[campaign_keywords[index] + ' [last touch]'] = kw;
					}
				}
				for (index = 0; index < campaign_keywords.length; ++index) {
					kw = getQueryParam(document.URL, campaign_keywords[index]);
					if (kw.length) {
						first_params[
							campaign_keywords[index] + ' [first touch]'
						] = kw;
					}
				}
				mixpanel.people.set(params);
				mixpanel.people.set_once(first_params);
				mixpanel.identify();
				mixpanel.register(params);
			};
			campaignParams();
			/* eslint-enable */

			mixpanel.track('View landing page');
		}
	},
	data() {
		return {
			show: false,
			bgClass: 'active',
			current: 'main',
			navLineClass: 'container',
			targetModal: ''
		};
	},
	updated() {
		this.$emit('current-nav-line', 'main');
		this.$emit('background-off', 'active');
		this.setCustomCursor();
	},
	created() {
		this.setCustomCursor();
	},
	mounted() {
		this.setCustomCursor();
	},
	methods: {
		closeModal() {
			this.show = false;
			this.targetModal = '';
		},
		toggleModal(target, state = true) {
			this.targetModal = target;
			if (!state) this.targetModal = '';
		},
		setCustomCursor() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.setCustomCursor);
				if (window.innerWidth > 768) {
					document.addEventListener(
						'mousemove',
						this.customMouseMove
					);

					const clickableElems = [...document.querySelectorAll('*')];
					clickableElems.some((el) => {
						let compStyles = window.getComputedStyle(el);
						if (
							compStyles.getPropertyValue('cursor') == 'pointer'
						) {
							el.addEventListener(
								'mousemove',
								this.customMouseMoveActive
							);
							el.addEventListener(
								'mouseleave',
								this.customMouseLeave
							);
						}
					});
				} else {
					document.removeEventListener(
						'mousemove',
						this.customMouseMove
					);
				}
			});
		},
		customMouseMove(event) {
			if (window.innerWidth <= 768) return;
			const cursor = document.querySelector('#custom-cursor');
			const mouseX = event.clientX - 2;
			const mouseY = event.clientY - 2;
			cursor.style.transform = `translate(${mouseX}px,${mouseY}px)`;
		},
		customMouseMoveActive() {
			if (window.innerWidth <= 768) return;
			const cursor = document.querySelector('#custom-cursor');
			cursor.classList.add('active');
		},
		customMouseLeave() {
			if (window.innerWidth <= 768) return;
			const cursor = document.querySelector('#custom-cursor');
			cursor.classList.remove('active');
		}
	}
};
</script>

<style lang="scss" scoped>
#root {
	height: max-content;

	position: relative;
	z-index: 1;
	padding: 0;
	padding-bottom: 220px;

	&::after {
		content: '';
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: url('~@/assets/images/background/pattern.png') center
			no-repeat;
		background-attachment: fixed;
		animation: bgRotate 1s linear forwards;
	}

	&.off {
		background: url(~@/assets/images/background/gradient.png) center
				no-repeat,
			url(~@/assets/images/background/about-us-bg.png) top no-repeat !important;
		&::after {
			background: none;
		}
	}

	@include laptop-md {
		padding-bottom: 180px;
	}

	@include laptop-standard {
		padding-bottom: 162px;
	}
	@include ipad {
		padding-bottom: 120px;

		&.off {
			background: url(~@/assets/images/background/about-us-bg.png) top
				no-repeat !important;
			background-size: 150% !important;
			&::after {
				background: none;
			}
		}
	}
}

.wrap {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-x: hidden;
}

.page-wrap {
	flex: 1 1 auto;
	@include ipad {
		padding-top: 50px;
	}
}
</style>
