/* eslint-disable no-undef */
import axios from 'axios';
import { checkField } from '../../validation/fields';

import nets from './networks-data/nets.json';
import validators from './networks-data/validators.json';
import socials from './networks-data/socials.json';

const API = process.env.VUE_APP_BACKEND_URL;

const FIELDS = [
	'yield',
	'marketCap',
	'inflation',
	'circulatingSupply',
	'stakingRate'
];

export default {
	state: () => ({
		nets,
		socials
	}),

	getters: {
		getNetworks: (state) =>
			state.nets.sort((prev, next) =>
				prev.priority === next.priority ? 0 : prev.priority ? -1 : 1
			),
		getNetworkSocials: (state) => state.socials
	},

	actions: {
		VALIDATED_NETS({ state }) {
			for (const network of state.nets) {
				if (validators[network.net]) {
					network.validator = validators[network.net];
				}
			}
			return state;
		},

		async SUPPORTED_NETS({ state }) {
			try {
				const { ok, data } = (
					await axios.get(`${API}/dao/supported_networks`)
				).data;

				if (!ok) {
					return;
				}

				for (const network of state.nets) {
					network.xctRewards = data.includes(network.net);
				}
				return state;
			} catch (error) {
				console.log(error);
			}
		},

		async GET_NETWORKS_MARKETCAP({ state, dispatch }, status) {
			const IntlNumberFormat = (number) => {
				return new Intl.NumberFormat().format(number);
			};

			try {
				await dispatch('SUPPORTED_NETS');

				if (status === 'validated') dispatch('VALIDATED_NETS');

				const { ok, data } = (
					await axios.get(`${API}/currency/marketcap`)
				).data;

				if (!ok) {
					return;
				}

				for (const network of state.nets) {
					if (data[network.net] && network.status.includes(status)) {
						FIELDS.forEach((field) => {
							const { format, value } = checkField(
								data[network.net],
								field
							);

							if (format) {
								data[network.net][field] =
									IntlNumberFormat(value);
							}

							if (
								value == 0 ||
								(typeof value == 'undefined' && isNaN(value)) ||
								(typeof value == 'string' && value === 'NaN')
							) {
								delete data[network.net][field];
							}
						});

						network.marketCap = data[network.net];
					}
				}

				return state;
			} catch (error) {
				console.log(error);
			}
		}
	},

	namespaced: true
};
