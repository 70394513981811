import { createStore } from 'vuex';
import i18n from './modules/i18n';
import socials from './modules/socials';
import links from './modules/links';
import team from './modules/team';
import partners from './modules/partners';
import xct from './modules/xct';
import networks from './modules/networks';
import platform from './modules/platform';
import staking from './modules/staking';
import articles from './modules/articles';
import extensions from './modules/extensions';

export default createStore({
	modules: {
		i18n,
		socials,
		links,
		team,
		partners,
		xct,
		networks,
		platform,
		staking,
		articles,
		extensions
	}
});
